import React from 'react';
import { useTranslation } from 'react-i18next';
import 'prismjs/themes/prism.css';
import 'prism-themes/themes/prism-one-dark.css';

import CodeBlock from './../helper/CodeBlock';

const SetupSection = () => {
  const { t } = useTranslation();

  // ### CODE BLOCKS #######################
  const javascriptCode = `
function onResultCallback(data) {
  console.log(data.text) // print generated text to console
}

const endpointName = 'llama2_chat';
const params = {
  text: 'Your text prompt'
};

doAPIRequest(endpointName, params, onResultCallback);`;

  const pythonCode = `
aime_api_client_interface import do_api_request 

api_server_address = 'https://api.aime.info'
endpoint_name = 'llama2_chat'
params = {'text': 'Your text prompt'}

result = do_api_request(api_server_address, endpoint_name, params)
print(result.get('text')) # print generated text to console`;

// ### /END CODE BLOCKS #######################


  return (

    <section className="bg-gray-200">
        <div className="max-w-screen-xl px-12 py-8 mx-auto lg:py-24 lg:px-12 lg:text-xl space-y-8">
          
            <h2>{t('setupAndStartSection.title')}</h2>
            <h3>{t('setupAndStartSection.environmentSetupTitle')}</h3>
            <p>{t('setupAndStartSection.environmentSetupContent')}</p>
            <CodeBlock language={'bash'} 
              code={'python3 -m venv venv\nsource ./venv/bin/activate'} 
            />

            <p>{t('setupAndStartSection.downloadCloneContent')}</p>
            <CodeBlock language='bash' code='git clone --recurse-submodules https://github.com/aime-team/aime-api-server.git' />

            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.alternativeInstallation') }}></p>
            <CodeBlock language='bash' code='git clone https://github.com/aime-team/aime-api-server.git' />

            <p>{t('setupAndStartSection.installPipPackages')}</p>
            <CodeBlock language='bash' code='pip install -r requirements.txt' />

            <h3>{t('setupAndStartSection.optionalInstallFFmpeg')}</h3>
            <p>{t('setupAndStartSection.ubuntuDebian')}</p>
            <CodeBlock language='bash' code='sudo apt install ffmpeg' />

            <h3>{t('setupAndStartSection.startingServerTitle')}</h3>
            <p>{t('setupAndStartSection.toStartServer')}</p>
            <CodeBlock language='bash' code='python3 run api_server.py [-H HOST] [-p PORT] [-c EP_CONFIG] [--dev]' />

            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.bootingAndLoadingEndpoints') }}></p>
            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.reachableAt') }}></p>
            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.readyToConnectWorkers') }}></p>

            <h2>{t('setupAndStartSection.computeWorkersTitle')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.turnExistingScriptIntoWorker') }}></p>
            <p>{t('setupAndStartSection.exampleWorkersAvailable')}</p>

            <h4>{t('setupAndStartSection.llama2ChatWorkerTitle')}</h4>
            <p><a href="https://github.com/aime-labs/llama2_chat" target="_blank" rel="noopener noreferrer">https://github.com/aime-labs/llama2_chat</a></p>

            <h4>{t('setupAndStartSection.stableDiffusionTitle')}</h4>
            <p><a href="https://github.com/aime-labs/stable_diffusion_xl" target="_blank" rel="noopener noreferrer">https://github.com/aime-labs/stable_diffusion_xl</a></p>

            <h4>{t('setupAndStartSection.seamlessCommunicationTitle')}</h4>
            <p><a href="https://github.com/aime-labs/seamless_communication" target="_blank" rel="noopener noreferrer">https://github.com/aime-labs/seamless_communication</a></p>

            <h2>{t('setupAndStartSection.clientInterfacesSectionTitle')}</h2>

            <h3>{t('setupAndStartSection.javascriptTitle')}</h3>
            <p>{t('setupAndStartSection.javascriptExample')}</p>
            <CodeBlock language='javascript' code={javascriptCode} />

            <h3>{t('setupAndStartSection.pythonTitle')}</h3>
            <p>{t('setupAndStartSection.pythonExample')}</p>
            <CodeBlock language='python' code={pythonCode} />

            <h3>{t('setupAndStartSection.moreToComeTitle')}</h3>
            <p>{t('setupAndStartSection.inProgressInterfaces')}</p>

            <h2>{t('setupAndStartSection.documentationSectionTitle')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.documentationSectionInfo1') }}></p>
            <p dangerouslySetInnerHTML={{ __html: t('setupAndStartSection.documentationSectionInfo2') }}></p>
        </div>
    </section>
  );
};

export default SetupSection;
