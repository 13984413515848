import React from 'react';
import { useTranslation } from 'react-i18next';

import FeatureListElement from './FeatureListElement';

const FeatureListSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-100">
        <div className="max-w-screen-xl px-12 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-12 md:px-12">
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                <div className="sm:text-lg">
                    <h2 className="mb-4 text-3xl md:text-3xl xs:text-2xl font-extrabold tracking-tight">{t('featureListSection.sectionTitle')}</h2>
                    <p className="mb-8 font-light lg:text-xl">{t('featureListSection.featureSectionDescription')}</p>
                    <ul className="pt-8 space-y-8 border-t border-gray-200 my-7">
                        <FeatureListElement contentString={t('featureListSection.fastDescription')}/>
                        <FeatureListElement contentString={t('featureListSection.scalableDescription')}/>
                        <FeatureListElement contentString={t('featureListSection.secureDescription')}/>
                        <FeatureListElement contentString={t('featureListSection.aggregatesDescription')}/>
                        <FeatureListElement contentString={t('featureListSection.integratableDescription')}/>
                        <FeatureListElement contentString={t('featureListSection.highPerformanceDescription')}/>
                        <FeatureListElement contentString={t('featureListSection.pythonicDescription')}/>
                    </ul>
                </div>
                <img className="mx-auto mb-4 rounded-lg lg:mb-0 lg:flex lg:max-w-96 md:max-w-48" alt="AIME AI Stack" src="/static/img/aime_ai-stack.png" title="AIME AI Stack" />
            </div>
        </div>
    </section>
  );
};

export default FeatureListSection;
