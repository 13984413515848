import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '..//helper/LanguageSelector';

const FooterSection = () => {
  const { t } = useTranslation();

  return (

        <footer className="g-gray-900 text-gray-300">
            <div className="max-w-screen-xl p-4 py-6 mx-12 mx-auto lg:py-16 md:p-8 lg:p-10">
                <div className="grid grid-cols-2 gap-8 md:grid-cols-2 lg:grid-cols-4">
                    <div>
                        <h3 className="mt-0 mb-6 text-sm font-semibold text-white uppercase">{t('footerSection.legal')}</h3>
                        <ul className="text-gray-500">
                            <li className="mb-2">
                                <a href="https://www.aime.info/privacy/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">{t('footerSection.privacyPolicy')}</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://github.com/aime-team/aime-api-server/blob/main/LICENSE" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">{t('footerSection.licensing')}</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://www.aime.info/imprint/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">{t('footerSection.imprint')}</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://www.aime.info/agb/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">{t('footerSection.terms')}</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="mt-0 mb-6 text-sm font-semibold text-white uppercase">Social Media</h3>
                        <ul className="text-gray-500">
                            <li className="mb-2">
                                <a href="https://mastodon.social/@AIME_hq" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">Mastodon</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://bsky.app/profile/aime-hq.bsky.social" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">Blue Sky</a>
                            </li>
                            <li className="mb-2">
                            <a href="https://www.linkedin.com/company/a-i-m-e/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">LinkedIn</a>
                            </li>
                            <li className="mb-2"> 
                                <a href="https://github.com/aime-team" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">GitHub</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="mt-0 mb-6 text-sm font-semibold text-white uppercase">{t('footerSection.company')}</h3>
                        <ul className="text-gray-500">
                            <li className="mb-2">
                                <a href="https://www.aime.info" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">AIME HPC Servers & Clusters</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://www.aime.info/gpucloud" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">AIME GPU Cloud</a>
                            </li>
                            <li className="mb-">
                                <a href="https://www.aime.info/blog" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">AIME Blog</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="mt-0 mb-6 text-sm font-semibold text-white uppercase">AIME API</h3>
                        <ul className="text-gray-500">
                            <li className="mb-2">
                                <a href="http://api.aime.info/llama2-chat/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">LlaMa2-Chat Demo</a>
                            </li>
                            <li className="mb-2">
                                <a href="http://api.aime.info/mixtral-chat/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">Mixtral-Chat Demo</a>
                            </li>
                            <li className="mb-2">
                                <a href="http://api.aime.info/sdxl-txt2img/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">Stable Diffusion XL Demo</a>
                            </li>
                            <li className="mb-2">
                                <a href="http://api.aime.info/sc-m4tv2/" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">Seamless Communication Demo</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://github.com/aime-team/aime-api-server" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">GitHub-Code</a>
                            </li>
                            <li className="mb-2">
                                <a href="http://api.aime.info/docs/index.html" target="_blank" rel="noreferrer" className="font-semibold text-gray-500 hover:underline">API Docs</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8" />
                <div className="text-center">
                    <div className="flex items-center justify-center mb-5">
                        <img src="./static/img/AIME-API_logo.svg" className="h-6 sm:h-9" alt="AIME GmbH Logo" />
                    </div>
                    <span className="block text-sm text-center text-gray-500">© 2024 AIME GmbH. {t('footerSection.allRightsReserved')}</span>
                    <LanguageSelector />
                </div>
            </div>
        </footer>
    );
};

export default FooterSection;