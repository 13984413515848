import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enJSON from './locale/en.json'
import deJSON from './locale/de.json'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    document: document,
    resources: {
      en: { ...enJSON },
      de: { ...deJSON },
    },
    //interpolation: { escapeValue: false } // 
});

i18next.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
});
  
document.documentElement.lang = i18next.language;

export default i18next;


// if problems with auto-translated page by google, use
// <html lang="en" className="notranslate" translate="no">
//   <head>
//     <meta name="google" content="notranslate" />
