import React, { useEffect } from 'react';

const MainTeaserCanvasBG_Waben = () => {
    useEffect(() => {
        const c = document.getElementById('main-teaser-canvas');
        const ctx = c.getContext('2d');
        const colors = ['#4FBFD7', '#D55151', '#F6BE5C', '#CBE4C9'];
        const parentElement = c.parentElement;

        var w = c.width = parentElement.offsetWidth,
            h = c.height = parentElement.offsetHeight,
            fillColor = '#213744',
            colorIndex = 0,
            
            opts = {
                len: 20,
                count: 3,
                baseTime: 10,
                addedTime: 10,
                dieChance: .05,
                spawnChance: 1,
                sparkChance: .1,
                sparkDist: 10,
                sparkSize: 1,
                
                color: 'hsl(hue,100%,light%)',
                baseLight: 50,
                addedLight: [50-10,50+10], //10,
                shadowToTimePropMult: 6,
                baseLightInputMultiplier: .01,
                addedLightInputMultiplier: .02,
                
                cx: w / 2,
                cy: h / 2,
                repaintAlpha: .04,
                hueChange: .1
            },
            
            lines = [],
            dieX = w / 2 / opts.len,
            dieY = h / 2 / opts.len,
            
            baseRad = Math.PI * 2 / 6;
        
        ctx.fillStyle = fillColor;
        ctx.fillRect( 0, 0, w, h );

        function loop() {
            window.requestAnimationFrame( loop );
            ctx.globalCompositeOperation = 'source-over';
            ctx.shadowBlur = 0;

            ctx.fillStyle = 'rgba(33,55,68,alp)'.replace( 'alp', opts.repaintAlpha ); //fillColor;
            ctx.fillRect( 0, 0, w, h );
            ctx.globalCompositeOperation = 'lighter';
            if( lines.length < opts.count && Math.random() < opts.spawnChance ) {
                lines.push( new Line() );
            }
            
            lines.forEach(function(line) { line.step(); });
            
        }
        
        function updateColor() {
            const color = colors[colorIndex];
            colorIndex = (colorIndex + 1) % colors.length;
            return color;
        }

        function Line(){
            this.reset();
        }

        Line.prototype.reset = function(){
            this.x = Math.random() * w - w / 2;
            this.y = Math.random() * h - h / 2;
            this.addedX = 0;
            this.addedY = 0;
            this.rad = 0;
            this.lightInputMultiplier = opts.baseLightInputMultiplier + opts.addedLightInputMultiplier * Math.random();
            this.color = updateColor();
            this.cumulativeTime = 0;
            this.beginPhase();
        }
        Line.prototype.beginPhase = function(){
            
            this.x += this.addedX;
            this.y += this.addedY;
            
            this.time = 0;
            this.targetTime = ( opts.baseTime + opts.addedTime * Math.random() ) |0;
            
            this.rad += baseRad * ( Math.random() < .5 ? 1 : -1 );
            this.addedX = Math.cos( this.rad );
            this.addedY = Math.sin( this.rad );
            
            if( Math.random() < opts.dieChance || this.x > dieX || this.x < -dieX || this.y > dieY || this.y < -dieY ){
                this.reset();
            }
        }
        Line.prototype.step = function(){
            
            ++this.time;
            ++this.cumulativeTime;
            
            if( this.time >= this.targetTime )
                this.beginPhase();
            
            var prop = this.time / this.targetTime,
                wave = Math.sin( prop * Math.PI / 2  ),
                x = this.addedX * wave,
                y = this.addedY * wave;
            
            ctx.shadowBlur = prop * opts.shadowToTimePropMult;
            ctx.fillStyle = ctx.shadowColor = this.color.replace( 'light', opts.baseLight + opts.addedLight * Math.sin( this.cumulativeTime * this.lightInputMultiplier ) );
            ctx.fillRect( opts.cx + ( this.x + x ) * opts.len, opts.cy + ( this.y + y ) * opts.len, 2, 2 );
            
            if( Math.random() < opts.sparkChance ){
                ctx.fillRect( opts.cx + ( this.x + x ) * opts.len + Math.random() * opts.sparkDist * ( Math.random() < .5 ? 1 : -1 ) - opts.sparkSize / 2, opts.cy + ( this.y + y ) * opts.len + Math.random() * opts.sparkDist * ( Math.random() < .5 ? 1 : -1 ) - opts.sparkSize / 2, opts.sparkSize, opts.sparkSize )
            }
        }
        loop();

        window.addEventListener( 'resize', function(){
            const c = document.getElementById('main-teaser-canvas');
            w = c.width = parentElement.offsetWidth;
            h = c.height = parentElement.offsetHeight;
            
            opts.cx = w / 2;
            opts.cy = h / 2;
            
            dieX = w / 2 / opts.len;
            dieY = h / 2 / opts.len;
        });

    }, []); // /END EFFECT HOOK

    return (
    <>
        <canvas id="main-teaser-canvas" className="w-full h-auto" height="100%" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
    </>
    );
};

export default MainTeaserCanvasBG_Waben;
