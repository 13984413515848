import React from 'react';
import './App.css';
import './i18n';

import MainTeaserSection from './templates/index/MainTeaserSection';
import TopNavigation from './templates/index/nav/TopNavigation';
import FeatureListSection from './templates/index/FeatureListSection';
import OverviewSection from './templates/index/OverviewSection';
import EndpointsSection from './templates/index/EndpointsSection';
import SetupSection from './templates/index/SetupSection';
import FooterSection from './templates/index/FooterSection';
import LicenseSection from './templates/index/LicenseSection';

function App() {

    React.useEffect(() => {
        // Matomo Tag Manager
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://log.aime.info/js/container_acZsiJE0.js'; s.parentNode.insertBefore(g,s);
    }, []);

    return (
        <div id="page-content">
            <TopNavigation />
            <MainTeaserSection />
            <FeatureListSection />
            <OverviewSection />
            <EndpointsSection />
            <SetupSection />
            <LicenseSection />
            <FooterSection />
        </div>
    );
}

export default App;
