import React from 'react';
import { useTranslation } from 'react-i18next';
import MainTeaserCanvasBG from './MainTeaserCanvasBG_Waben';

const MainTeaserSection = () => {
  const { t } = useTranslation(['translation', 'mainTeaserSection']);

  return (
    <section className="relative">
        <div className="bg-aime_darkblue w-full h-fit overflow-visible mx-auto lg:pt-48 lg:pb-48 md:pb-36 pt-36 pb-24 py-8 px-12 mx-auto text-left text-white lg:py-16 lg:px-48">
            <div id="teaser-container" className="relative w-full h-fit z-20">
                <h1 className="lg:mt-80 md:mt-36 sm:mt-24 mb-3 text-4xl font-extrabold tracking-tight leading-none sm: text-3xl md:text-4xl lg:text-5xl">{ t('mainTeaserSection.title') }</h1>
                <h2 className="mb-7 text-xl font-bold tracking-tight leading-none md:text-xl lg:text-4xl">{t('mainTeaserSection.subTitle')}</h2>
                <div className="mb-8 text-lg font-normal lg:text-xl">{t('mainTeaserSection.description')}</div>
            </div>
            <div id="canvas-container" className="absolute top-0 left-0 z-0 w-full h-full">
                <MainTeaserCanvasBG />
            </div>
        </div>
    </section>
  );
};

export default MainTeaserSection;
