import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-python';

const CodeBlock = ({ code, language }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [code, language]);

  return (
    <pre className="w-fit max-w-full">
      <code className={`language-${language}`}>
        {code.trim()}
      </code>
    </pre>
  );
};

export default CodeBlock;
