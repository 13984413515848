import React from 'react';

const FeatureListElement = ({contentString}) => {
  return (
    <li className="flex space-x-3">
        <svg className="h-6 w-6 flex-none fill-sky-100 stroke-sky-500 stroke-2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="11" />
            <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
        </svg>
        <span className="text-base font-bold text-gray-900">{contentString}</span>
    </li>
  );
};

export default FeatureListElement;
