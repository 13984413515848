import React from 'react';
import { useTranslation } from 'react-i18next';

const EndpointCardElement = ({endpointTitle, endpointDescription, imagePath, demoLinkPath, codeLinkPath}) => {
    const { t } = useTranslation();
    
    return (
        <div className="bg-white border rounded-xl shadow-sm sm:flex md:max-w-lg mb-8">
            <div className="flex-shrink-0 relative w-full rounded-t-xl overflow-hidden pt-[40%] sm:rounded-s-xl">
            <a href={demoLinkPath}>
                <img className="size-full absolute top-0 start-0 object-cover" src={imagePath} alt={endpointTitle} />
            </a>
            </div>
            <div className="flex flex-wrap">
                <div className="p-4 flex flex-col h-full sm:p-7">
                    <h3 className="text-lg font-bold text-gray-80 mt-2">{endpointTitle}</h3>
                    <p className="mt-1 text-gray-500">{endpointDescription}</p>
                    <div className="mt-1 sm:mt-auto">
                    <ul>
                        <li><a href={demoLinkPath}>{t('endpointSection.demoServerTitle')}</a></li>
                        <li><a href={codeLinkPath} target="_blank" rel="noreferrer">{t('endpointSection.sourceCodeTitle')}</a></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

EndpointCardElement.defaultProps = {
    demoLinkPath: '#',
    codeLinkPath: '#'
};

export default EndpointCardElement;
