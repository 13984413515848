import React from 'react';
import { useTranslation } from 'react-i18next';

const OverviewSection = () => {
  const { t } = useTranslation();

  return (  
    <section className="bg-gray-800 text-white">
        <div className="max-w-screen-xl px-12 py-8 mx-auto space-y-8 lg:py-24 lg:px-12">
            <h2 className="text-3xl font-extrabold tracking-tight">{t('overviewSection.overviewTitle')}</h2>
            <p className="font-light lg:text-xl">{t('overviewSection.overviewContent.firstParagraph')}</p>
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                <img className="mx-auto mb-4 rounded-lg lg:mb-0 lg:flex" alt={t('overviewSection.overviewContent.architectureImage.altText')} src={t('overviewSection.overviewContent.architectureImage.src')} title={t('overviewSection.overviewContent.architectureImage.title')} />
                <div className="items-center gap-8">
                    <div className="sm:text-lg">
                        {/* <div className="pt-8 space-y-5 border-t my-7 border-gray-700"> */}
                            <h3>{t('overviewSection.overviewContent.apiServerTitle')}</h3>
                            <p>{t('overviewSection.overviewContent.apiServerContent')}</p>
                            <h3>{t('overviewSection.overviewContent.computeWorkersTitle')}</h3>
                            <p>{t('overviewSection.overviewContent.computeWorkersContent')}</p>
                            <h3>{t('overviewSection.overviewContent.clientsTitle')}</h3>
                            <p>{t('overviewSection.overviewContent.clientsContent')}</p>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default OverviewSection;
