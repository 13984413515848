import React from 'react';
import { useTranslation } from 'react-i18next';

const LicenseSection = () => {
  const { t } = useTranslation();

  return (
    <section className="">
        <div className="max-w-screen-xl px-12 py-8 mx-auto mb-12 lg:py-16 lg:px-20 space-y-8">
            <div className="max-w-screen-sm mx-auto text-center">
                <h2 className="mb-8 text-3xl font-extrabold leading-tight tracking-tight text-gray-900">{t('licenseSection.licesneSectionTitle')}</h2>
                <p className="mb-10 font-light text-gray-500 md:text-lg lg:text-xl" dangerouslySetInnerHTML={{ __html: t('licenseSection.licenseInfo') }}></p>
                <a href="http://www.aime.info" target="_blank" rel="noreferrer" className="text-aime_darkblue text-bold bg-aime_orange hover:bg-aime_darkblue hover:text-white focus:ring-4 rounded-lg px-4 py-5 2xs:px-2 xs:px-4 mt-5 focus:outline-none">{t('licenseSection.buttonText')}</a>
            </div>
        </div>
    </section>
  );
};

export default LicenseSection;
